<template>
  <div>
    <template v-for="item of list">
      <BrandSubassembly
        :background-url="item.content.bgUrl"
        :brand-id="item.brandId"
        :commodity-list="item.commodityList"
        :key="item.id"
        :logo="item.content.logoUrl"
        :text-color="item.content.sloganColor"
        :text-layout="item.content.bgText"
        :text-pre="item.content.introduce"
        :text-pre-color="item.content.introduceColor"
        :text-title="item.content.slogan"
        event_source="brand_pavilion_internationalization"
      />
    </template>
  </div>
</template>

<script>
import { getListByBrandList } from '@/api/website/brand-pavilion';
import BrandSubassembly from '@/components/views/website/brand-subassembly';
import { isJSON } from '@/utils/validate';
export default {
  name: '',
  components: {
    BrandSubassembly
  },
  created() {
    this.getData();
  },
  data() {
    return {
      brandType: 'GLOBAL', // ：自有品牌-SELF，国际品牌-GLOBAL
      list: []
    };
  },
  methods: {
    async getData() {
      const axiosData = await getListByBrandList('GLOBAL');
      if (axiosData.success) {
        const list = (axiosData.data ?? []).map((item) => ({
          ...item,
          content: isJSON(item.content) ? JSON.parse(item.content) : {}
        }));
        this.list = list;
      }
    }
  }
};
</script>

<style lang='scss'  scoped>
</style>
